/** @format */

import { createSlice } from '@reduxjs/toolkit';

export const initialState = {};

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {},
});

export const {} = notificationSlice.actions;
export default notificationSlice.reducer;
