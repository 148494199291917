/** @format */

import queryString from 'query-string';
import {NotifyRequest} from 'stores/notification/types';
import {AnyObject} from 'interfaces/shared';
import {isObjectLike} from 'lodash';

/**
 * @param time miliseconds
 * @default 3000
 */
export const sleep = (time = 3000) => new Promise(r => setTimeout(r, time));

export const getInitialsName = (name: string): string => {
  if (!name) return '';
  const words = name.split(' ');
  let result = '';
  for (const word of words) {
    result += word.charAt(0).toUpperCase();
  }
  if (!result) {
    console.warn('Could not get abbr from name');
    result = name;
  }
  return result;
};

export const hexToRgba = (hex: string, alpha: number): string => {
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    let c = hex.substring(1).split('');
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    const cValue = parseInt(c.join(''), 16);
    const r = (cValue >> 16) & 255;
    const g = (cValue >> 8) & 255;
    const b = cValue & 255;
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }
  throw new Error('Mã màu hex không hợp lệ');
};

export const isFile = (value: any): boolean => value instanceof File || value instanceof Blob;

export const transformPostData = (object: AnyObject = {}) => {
  const newObject: AnyObject = {};
  for (const [key, value] of Object.entries(object)) {
    if (isObjectLike(value)) {
      newObject[key] = JSON.stringify(value);
    } else {
      newObject[key] = value;
    }
  }
  return queryString.stringify(newObject);
};

export const transformPostFormData = (object: AnyObject | FormData = {}): FormData => {
  if (object instanceof FormData) {
    return object;
  }
  const formData = new FormData();
  for (const [key, value] of Object.entries(object)) {
    if (isObjectLike(value)) {
      if (Array.isArray(value) && value.every(isFile)) {
        value.forEach((v: any) => {
          formData.append(key, v);
        });
      } else if (isFile(value)) {
        formData.append(key, value);
      } else {
        formData.append(key, JSON.stringify(value));
      }
    } else if (value != null) {
      formData.append(key, value);
    }
  }
  return formData;
};

export const checkStringType = (input: string) => {
  const num = Number(input);
  if (!Number.isNaN(num) && input.trim() !== '') {
    return false;
  } else {
    return true;
  }
};

export const getBase64 = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
};

export const removeUndefinedObject = (obj: any) => {
  Object.keys(obj).forEach(k => {
    if (obj[k] === '' || obj[k] === null || obj[k] === undefined) {
      delete obj[k];
    }
    if (typeof obj[k] === 'object' && !Array.isArray(obj[k])) {
      removeUndefinedObject(obj[k]);
    }
  });
  return obj;
};

export const getMessageType = (contentEN: string, contentVN: string): NotifyRequest['message_type'] => {
  const hasContentEn = contentEN !== undefined && contentEN !== '';
  const hasContentVn = contentVN !== undefined && contentVN !== '';
  return hasContentEn && hasContentVn ? 'news' : 'noti';
};


export const DEFAULT_LOCALES = 'en-EN';
export const NUMBER_FORMAT = new Intl.NumberFormat(DEFAULT_LOCALES, { maximumFractionDigits: 0 });
export const convertCurrency = (currency?: number, suffix = '₫', locales = DEFAULT_LOCALES) => {
  const _formatter = locales === DEFAULT_LOCALES ? NUMBER_FORMAT : new Intl.NumberFormat(locales, { minimumFractionDigits: 0, maximumFractionDigits: 0 });
  if (currency === undefined || currency === 0) {
    return `0 ${suffix}`;
  }
  return `${_formatter.format(currency)} ${suffix}`;
};
