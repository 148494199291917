import axios from 'axios';
import {transformPostData, transformPostFormData} from 'helpers';
import {AnyObject, ApiResponse} from 'interfaces/shared';
import {logoutAdminAccount} from 'services/auth';
import {getCookie} from 'helpers/cookie';
import {API_CAR_URL, API_URL, isCar} from 'constants/key';

const instance = axios.create({baseURL: isCar ? API_CAR_URL : API_URL, timeout: 30000});

instance.interceptors.request.use(
  async config => {
    const accessToken = getCookie('evd-a').token;
    if (accessToken) {
      // config.headers.set('accept-language', 'vn');
      config.headers.setAuthorization(`Bearer ${accessToken}`);
    }
    if (process.env.NODE_ENV === 'development') {
      console.log(`%c [REQUEST] ${config?.url}`, 'color: #458B00; font-weight: bold', config);
      console.log('url:', config.url);
      console.log('method:', config.method);
      console.log('data:', config.data);
      console.log('params:', config.params);
    }
    return config;
  },
  error => {
    throw error;
  },
);

instance.interceptors.response.use(
  response => {
    if (process.env.NODE_ENV === 'development') {
      console.log(`%c [RESPONSE] ${response.config.url}`, 'color: #CD950C; font-weight: bold', response);
      console.log('status:', response.status);
      console.log('data:', response.data);
    }
    return response;
  },
  async error => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      return logoutAdminAccount();
    }
    return Promise.reject(error);
  },
);

const api = {
  get: async <T = void>(url: string, params?: any): Promise<T> => {
    const response = await instance.get<T>(url, {params});
    return response.data;
  },
  /** form-urlencoded */
  post: async <T = ApiResponse>(url: string, body?: AnyObject, params?: any): Promise<T> => {
    const data = transformPostData(body);
    const response = await instance.post<T>(url, data, {params});
    return response.data;
  },
  /** form-data */
  postForm: async <T = ApiResponse>(url: string, body?: AnyObject | FormData, params?: any): Promise<T> => {
    const data = transformPostFormData(body);
    const response = await instance.postForm<T>(url, data, {params});
    return response.data;
  },
  /** raw-JSON */
  postRaw: async <T = ApiResponse>(url: string, body?: AnyObject | FormData, params?: any): Promise<T> => {
    const data = JSON.stringify(body);
    const response = await instance.post<T>(url, data, {params, headers: {'Content-Type': 'application/json'}});
    return response.data;
  },
  /** form-urlencoded */
  put: async <T = ApiResponse>(url: string, body?: AnyObject, params?: any): Promise<T> => {
    const data = transformPostData(body);
    const response = await instance.put<T>(url, data, {params});
    return response.data;
  },
  /** form-data */
  putForm: async <T = ApiResponse>(url: string, body?: AnyObject | FormData, params?: any): Promise<T> => {
    const data = transformPostFormData(body);
    const response = await instance.putForm<T>(url, data, {params});
    return response.data;
  },
  /** raw-JSON */
  putRaw: async <T = ApiResponse>(url: string, body?: AnyObject | FormData, params?: any): Promise<T> => {
    const data = JSON.stringify(body);
    const response = await instance.put<T>(url, data, {params, headers: {'Content-Type': 'application/json'}});
    return response.data;
  },
  /** form-urlencoded */
  patch: async <T = ApiResponse>(url: string, body?: AnyObject, params?: any): Promise<T> => {
    const data = transformPostData(body);
    const response = await instance.patch<T>(url, data, {params});
    return response.data;
  },
  /** form-data */
  patchForm: async <T = ApiResponse>(url: string, body?: AnyObject | FormData, params?: any): Promise<T> => {
    const data = transformPostFormData(body);
    const response = await instance.patchForm<T>(url, data, {params});
    return response.data;
  },
  /** raw-JSON */
  patchRaw: async <T = ApiResponse>(url: string, body?: AnyObject | FormData, params?: any): Promise<T> => {
    const data = JSON.stringify(body);
    const response = await instance.patch<T>(url, data, {params, headers: {'Content-Type': 'application/json'}});
    return response.data;
  },
  delete: async <T = ApiResponse>(url: string, params?: any): Promise<T> => {
    const response = await instance.delete<T>(url, {params});
    return response.data;
  },
};

export default api;
