/** @format */

import storageSession from 'redux-persist/lib/storage/session';
import {defaultState, DefaultState} from 'interfaces/redux';
import {PersistConfig, persistReducer} from 'redux-persist';
import {createSlice} from '@reduxjs/toolkit';
import {getUserGroupsAction} from './actions';
import type {IUserGroup} from './types';

export interface State {
  userGroupsList: DefaultState<IUserGroup[]>;
}
export const initialState: State = {
  userGroupsList: defaultState,
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getUserGroupsAction.pending, state => {
      state.userGroupsList.isLoading = true;
      state.userGroupsList.error = undefined;
    });
    builder.addCase(getUserGroupsAction.fulfilled, (state, action) => {
      state.userGroupsList.isLoading = false;
      state.userGroupsList.data = action.payload['hydra:member'];
    });
    builder.addCase(getUserGroupsAction.rejected, (state, action) => {
      state.userGroupsList.isLoading = false;
      state.userGroupsList.error = action.payload || action.error;
    });
  },
});

const persistConfig: PersistConfig<State> = {
  key: 'user',
  storage: storageSession,
  whitelist: [],
};

export const {} = slice.actions;

export default persistReducer(persistConfig, slice.reducer);
