import {AsyncThunk, createAsyncThunk, GetThunkAPI} from '@reduxjs/toolkit';
import {useCallback, useState} from 'react';
import {useDeepCompareEffect} from 'hooks/common';
import {AppDispatch, RootState} from 'stores';
import {useAppDispatch} from 'hooks/redux';
import {AsyncThunkConfig} from 'interfaces/redux';
import {SKIP_TOKEN} from './constants';
import {message, notification, TablePaginationConfig} from 'antd';
import {
  AnyObject,
  ApiResponse,
  ApiResponseData,
  AppAxiosError,
  PagingParams,
  PagingResponseData,
} from 'interfaces/shared';
import useReady from 'hooks/useReady';

export const createAppAsyncThunk = createAsyncThunk.withTypes<AsyncThunkConfig>();

export const handleThunkError = (
  thunkApi: GetThunkAPI<{state: RootState; dispatch: AppDispatch}>,
  err: unknown,
  isShowToast?: boolean,
) => {
  let error = err as AppAxiosError;
  if (error.response) {
    if (isShowToast) {
      notification.error({
        message: error.code,
        showProgress: true,
        description: error.response?.data.message,
        duration: 5,
      });
    }
    return thunkApi.rejectWithValue(error.response.data);
  } else {
    throw err;
  }
};

export const createQueryPagingHook =
  <D, R extends PagingResponseData<D>, A extends PagingParams>(asyncThunk: AsyncThunk<R, A, AsyncThunkConfig>) =>
  (params: A, {skip = false} = {}) => {
    const dispatch = useAppDispatch();
    const isReady = useReady();
    const [total, setTotal] = useState(0);
    const [isLoading, setIsLoading] = useState(!(skip || params === SKIP_TOKEN));
    const [data, setData] = useState<R['hydra:member']>();
    const [error, setError] = useState<unknown>();
    const [countRefresh, setCountRefresh] = useState(0);
    const [result, setResult] = useState<R>();

    const query = useCallback(
      async (params: A) => {
        try {
          setIsLoading(true);
          setError(undefined);
          const res = await dispatch(asyncThunk(params)).unwrap();
          setTotal(res['hydra:totalItems']);
          setData(res['hydra:member']);
          setResult(res);
          return res;
        } catch (e) {
          setError(e);
        } finally {
          setIsLoading(false);
        }
      },
      [data, dispatch],
    );

    const refetch = useCallback(() => {
      setCountRefresh(c => c + 1);
    }, []);

    useDeepCompareEffect(() => {
      if (isReady) {
        if (skip || params === SKIP_TOKEN) {
          return;
        }
        const requestParams = {...params, page: params?.page || 1, itemsPerPage: params?.itemsPerPage || 10};
        query(requestParams);
      }
    }, [skip, params, isReady, countRefresh]);

    const pagingConfigs: TablePaginationConfig = {
      className: 'custom-pagination',
      onChange: (current: number, size: number) => {
        const requestParams = {...params, page: current, itemsPerPage: size};
        query(requestParams);
      },
      showTotal: (total, range) => {
        return `${range[0] ? range[0] : 1}-${range[1] ? range[1] : total} of ${total} items`;
      },
      total,
    };

    return {
      total,
      isLoading,
      error,
      setData,
      refetch,
      query,
      data,
      result,
      pagingConfigs,
    };
  };

export const createQueryHook =
  <D, R extends ApiResponseData<D> | ApiResponse | AnyObject | undefined, A>(
    asyncThunk: AsyncThunk<R, A, AsyncThunkConfig>,
  ) =>
  (params: A, {skip = false} = {}) => {
    const dispatch = useAppDispatch();
    const isReady = useReady();
    const [isLoading, setIsLoading] = useState(!(skip || params === SKIP_TOKEN));
    const [data, setData] = useState<R extends ApiResponseData<D> ? R['hydra:member'] : undefined>();
    const [error, setError] = useState<unknown>();
    const [result, setResult] = useState<R>();
    const [countRefresh, setCountRefresh] = useState(0);

    const request = useCallback(
      async (params: A) => {
        try {
          setIsLoading(true);
          setError(undefined);
          const res = await dispatch(asyncThunk(params)).unwrap();
          if (res && 'hydra:member' in res) {
            setData(res['hydra:member']);
          }
          setIsLoading(false);
          setResult(res);
          return res;
        } catch (e) {
          setError(e);
          setIsLoading(false);
          throw e;
        }
      },
      [dispatch],
    );

    const refresh = useCallback(() => {
      setCountRefresh(c => c + 1);
    }, []);

    useDeepCompareEffect(() => {
      if (isReady) {
        if (skip || params === SKIP_TOKEN) {
          return;
        }
        request(params).catch(() => {});
      }
    }, [skip, params, isReady, countRefresh]);

    return {
      isLoading,
      error,
      request,
      data,
      result,
      refresh,
      setData,
      setResult,
    };
  };

export const createMutationHook =
  <R, A>(asyncThunk: AsyncThunk<R, A, AsyncThunkConfig>) =>
  () => {
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [result, setResult] = useState<R>();
    const [error, setError] = useState<unknown>();

    const request = useCallback(
      async (arg: A) => {
        try {
          setIsLoading(true);
          setError(undefined);
          const res = await dispatch(asyncThunk(arg)).unwrap();
          setIsLoading(false);
          setResult(res);
          return res;
        } catch (e) {
          setError(e);
          setIsLoading(false);
          throw e;
        }
      },
      [dispatch],
    );

    return {
      isLoading,
      error,
      request,
      result,
    };
  };
