export function getCookie(name: CookieName) {
  let match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
  if (match) return JSON.parse(match[2]);
}
export function setCookie(name: CookieName, payload: object) {
  document.cookie = `${name}=${JSON.stringify(payload)};path=/;Max-Age=${60 * 60 * 24 * 7}`;
}
export function removeCookie(name: CookieName) {
  document.cookie = `${name}=`;
}

export const isLogged = () => {
  return getCookie('evd-a') && !!getCookie('evd-a').token;
};
