import React, {useEffect, useState} from 'react';

export default function useReady() {
  const [isReady, setReady] = useState(false);

  useEffect(() => {
    setReady(true);
  }, [isReady]);

  return isReady;
}
