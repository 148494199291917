/** @format */

import storageSession from 'redux-persist/lib/storage/session';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {defaultState, DefaultState} from 'interfaces/redux';
import {PersistConfig, persistReducer} from 'redux-persist';
import {getStationAction} from './action';
import type {IStation} from './types';

export interface StationState {
  stationList: DefaultState<IStation[]>;
}
export const initialState: StationState = {
  stationList: defaultState,
};

const appSlice = createSlice({
  name: 'station',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getStationAction.pending, state => {
      state.stationList.isLoading = true;
      state.stationList.error = undefined;
    });
    builder.addCase(getStationAction.fulfilled, (state, action) => {
      state.stationList.isLoading = false;
      state.stationList.data = action.payload['hydra:member'];
    });
    builder.addCase(getStationAction.rejected, (state, action) => {
      state.stationList.isLoading = false;
      state.stationList.error = action.payload || action.error;
    });
  },
});

const persistConfig: PersistConfig<StationState> = {
  key: 'station',
  storage: storageSession,
  whitelist: ['box'],
};

export const {} = appSlice.actions;

export default persistReducer(persistConfig, appSlice.reducer);
