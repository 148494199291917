/** @format */

import './style.css';
import 'antd/dist/reset.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import {mainRoutes} from 'routes/router';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import {THEME_ANTD} from 'themes/color';
import {graphQlClient} from 'graphql/connect';
import {ApolloProvider} from '@apollo/client';
import {Provider as ReduxProvider} from 'react-redux';
import {ConfigProvider as AntdThemesProvider} from 'antd';
import {PersistGate} from 'redux-persist/integration/react';
import {persistor, store} from 'stores';
import {APIProvider} from '@vis.gl/react-google-maps';
import {MAP_API_KEY} from 'constants/key';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const router = createBrowserRouter(mainRoutes);

root.render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      {/* <PersistGate loading={null} persistor={persistor}> */}
        <AntdThemesProvider theme={THEME_ANTD}>
          <ApolloProvider client={graphQlClient}>
            <APIProvider apiKey={MAP_API_KEY}>
              <RouterProvider router={router} />
            </APIProvider>
          </ApolloProvider>
        </AntdThemesProvider>
      {/* </PersistGate> */}
    </ReduxProvider>
  </React.StrictMode>,
);

