/** @format */

import storageSession from 'redux-persist/lib/storage/session';
import {createSlice} from '@reduxjs/toolkit';
import {defaultState, DefaultState} from 'interfaces/redux';
import {PersistConfig, persistReducer} from 'redux-persist';
import {getAreaTypeAction, getCarConnectorTypeAction, getCurrentDirectionAction, getStationOpenDayAction} from './action';
import type {IAreaType, ICarConnectorType, ICurrentDirection, IStationOpenDay} from './types';

export interface State {
  areaTypeList: DefaultState<IAreaType[]>;
  stationOpenDayList: DefaultState<IStationOpenDay[]>;
  carConnectorTypeList: DefaultState<ICarConnectorType[]>;
  currentDirectionList: DefaultState<ICurrentDirection[]>;
}
export const initialState: State = {
  areaTypeList: defaultState,
  carConnectorTypeList: defaultState,
  currentDirectionList: defaultState,
  stationOpenDayList: defaultState,
};

const slice = createSlice({
  name: 'carSettings',
  initialState,
  reducers: {},
  extraReducers: builder => {
    // #region Area Type
    builder.addCase(getAreaTypeAction.pending, state => {
      state.areaTypeList.isLoading = true;
      state.areaTypeList.error = undefined;
    });
    builder.addCase(getAreaTypeAction.fulfilled, (state, action) => {
      state.areaTypeList.isLoading = false;
      state.areaTypeList.data = action.payload['hydra:member'];
    });
    builder.addCase(getAreaTypeAction.rejected, (state, action) => {
      state.areaTypeList.isLoading = false;
      state.areaTypeList.error = action.payload || action.error;
    });
    // #endregion

    // #region Car Connector Type
    builder.addCase(getCarConnectorTypeAction.pending, state => {
      state.carConnectorTypeList.isLoading = true;
      state.carConnectorTypeList.error = undefined;
    });
    builder.addCase(getCarConnectorTypeAction.fulfilled, (state, action) => {
      state.carConnectorTypeList.isLoading = false;
      state.carConnectorTypeList.data = action.payload['hydra:member'];
    });
    builder.addCase(getCarConnectorTypeAction.rejected, (state, action) => {
      state.carConnectorTypeList.isLoading = false;
      state.carConnectorTypeList.error = action.payload || action.error;
    });
    // #endregion

    // #region Station Open Day
    builder.addCase(getStationOpenDayAction.pending, state => {
      state.stationOpenDayList.isLoading = true;
      state.stationOpenDayList.error = undefined;
    });
    builder.addCase(getStationOpenDayAction.fulfilled, (state, action) => {
      state.stationOpenDayList.isLoading = false;
      state.stationOpenDayList.data = action.payload['hydra:member'];
    });
    builder.addCase(getStationOpenDayAction.rejected, (state, action) => {
      state.stationOpenDayList.isLoading = false;
      state.stationOpenDayList.error = action.payload || action.error;
    });
    // #endregion

    // #region Current Direction
    builder.addCase(getCurrentDirectionAction.pending, state => {
      state.currentDirectionList.isLoading = true;
      state.currentDirectionList.error = undefined;
    });
    builder.addCase(getCurrentDirectionAction.fulfilled, (state, action) => {
      state.currentDirectionList.isLoading = false;
      state.currentDirectionList.data = action.payload['hydra:member'];
    });
    builder.addCase(getCurrentDirectionAction.rejected, (state, action) => {
      state.currentDirectionList.isLoading = false;
      state.currentDirectionList.error = action.payload || action.error;
    });
    // #endregion
  },
});

const persistConfig: PersistConfig<State> = {
  key: 'carSettings',
  storage: storageSession,
  whitelist: [''],
};

export const {} = slice.actions;

export default persistReducer(persistConfig, slice.reducer);
