/** @format */

import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {PersistConfig, persistReducer} from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';

export interface AppState {
  service: 'bike' | 'car';
}
export const initialState: AppState = {
  service: 'bike',
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    toggleService: (state, action: PayloadAction<AppState['service']>) => {
      state.service = action.payload;
    },
  },
});

const persistConfig: PersistConfig<AppState> = {
  key: 'app',
  storage: storageSession,
  whitelist: ['service'],
};

export const {toggleService} = appSlice.actions;

export default persistReducer(persistConfig, appSlice.reducer);
