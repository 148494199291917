import api from 'utils/api';
import {PagingParams, PagingResponseData} from 'interfaces/shared';
import {createAppAsyncThunk, handleThunkError} from 'stores/helpers';
import type {IAreaType, IBalanceAction, ICarConnectorType, ICarMarket, ICurrentDirection, IStationOpenDay} from './types';
import apiCar from 'utils/apiCar';

export const getAreaTypeAction = createAppAsyncThunk('getAreaTypeAction', async (params: PagingParams, thunkApi) => {
  try {
    return await apiCar.get<PagingResponseData<IAreaType>>('api/area_types', params);
  } catch (error) {
    return handleThunkError(thunkApi, error);
  }
});

export const getCurrentDirectionAction = createAppAsyncThunk(
  'getCurrentDirectionAction',
  async (params: PagingParams, thunkApi) => {
    try {
      return await apiCar.get<PagingResponseData<ICurrentDirection>>('api/current_directions', params);
    } catch (error) {
      return handleThunkError(thunkApi, error);
    }
  },
);

export const getStationOpenDayAction = createAppAsyncThunk(
  'getStationOpenDayAction',
  async (params: PagingParams, thunkApi) => {
    try {
      return await apiCar.get<PagingResponseData<IStationOpenDay>>('api/station_open_days', params);
    } catch (error) {
      return handleThunkError(thunkApi, error);
    }
  },
);

export const getBalanceAction = createAppAsyncThunk('getBalanceAction', async (params: PagingParams, thunkApi) => {
  try {
    return await apiCar.get<PagingResponseData<IBalanceAction>>('api/balance_actions', params);
  } catch (error) {
    return handleThunkError(thunkApi, error);
  }
});

export const getCarMarketAction = createAppAsyncThunk('getCarMarketAction', async (params: PagingParams, thunkApi) => {
  try {
    return await apiCar.get<PagingResponseData<ICarMarket>>('api/car_markets', params);
  } catch (error) {
    return handleThunkError(thunkApi, error);
  }
});

export const getCarConnectorTypeAction = createAppAsyncThunk(
  'getCarConnectorTypeAction',
  async (params: PagingParams, thunkApi) => {
    try {
      return await apiCar.get<PagingResponseData<ICarConnectorType>>('api/car_connector_types', params);
    } catch (error) {
      return handleThunkError(thunkApi, error);
    }
  },
);
